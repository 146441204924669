var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.userGroups,"search":_vm.search,"loading":_vm.isLoading,"sort-by":"attributes.name"},scopedSlots:_vm._u([{key:`item.attributes.name`,fn:function({ item }){return [_c('p',{staticClass:"info--text subtitle-2 font-weight-medium mb-1"},[_vm._v(" "+_vm._s(item.attributes.name)+" ")])]}},{key:`item.users`,fn:function({ item }){return _vm._l((item.attributes.users &&
        item.attributes.users.data),function(contact,i){return _c('ul',{key:i,staticClass:"pa-0"},[(contact.attributes.roles[1] !== 'forest_manager')?_c('li',[_vm._v(" "+_vm._s(contact.attributes.info.name)+" ")]):_vm._e()])})}},{key:`item.forestManagers`,fn:function({ item }){return [_vm._l((item.attributes.customAttributes.forestManagers),function(fm,i){return _c('ul',{key:i,staticClass:"pa-0"},[_c('li',[_vm._v(" "+_vm._s(fm.attributes.name)+" ")])])}),_c('ul',{staticClass:"pa-0 text--secondary"},[(!item.attributes.customAttributes.forestManagers.length)?_c('li',[_vm._v(" - ")]):_vm._e()])]}},{key:`item.validated`,fn:function({ item }){return [_c('validate-user-group',{attrs:{"user-group":item}})]}},{key:`item.attributes.customAttributes.validatedFrom`,fn:function({ item }){return [(item.attributes.customAttributes.validatedFrom)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.attributes.customAttributes.validatedFrom,'LL'))+" ")]):_vm._e()]}},{key:`item.attributes.customAttributes.validatedTo`,fn:function({ item }){return [(item.attributes.customAttributes.validatedTo)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.attributes.customAttributes.validatedTo,'LL'))+" ")]):_vm._e()]}},{key:`item.attributes.customAttributes.validationHash`,fn:function({ item }){return [_c('a',{attrs:{"href":`https://rinkeby.etherscan.io/tx/${item.attributes.customAttributes.validationHash}`,"target":"_blank"}},[_c('span',{staticClass:"purple--text text--lighten-1 mb-0 d-inline-block text-truncate",staticStyle:{"max-width":"100px"}},[_vm._v(" "+_vm._s(item.attributes.customAttributes.validationHash)+" ")])])]}},{key:`item.id`,fn:function({ item }){return [_c('BaseTableActions',{attrs:{"module-name":"userGroup","item":item,"to-show":{
        name: 'user-group-show',
        params: {
          groupId: item.id,
          category: item.attributes.category
        }
      },"to-edit":{
        name: 'user-group-edit',
        params: {
          groupId: item.id,
          category: item.attributes.category
        }
      }}})]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }