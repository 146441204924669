<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="green" v-bind="attrs" v-on="on"
        ><v-icon>
          {{
            isValidated
              ? 'mdi-checkbox-marked-outline'
              : 'mdi-checkbox-blank-outline'
          }}</v-icon
        ></v-btn
      >
    </template>
    <!--  -->
    <!-- Unvalidation -->
    <!--  -->
    <v-card v-if="isValidated">
      <v-card-title>
        <!-- Remove Company Validation <v-spacer></v-spacer
        ><v-icon left color="error">mdi-account-remove</v-icon> -->
      </v-card-title>
      <v-card-text>
        <p class="mt-4">
          <!-- Are you sure you want to remove validation from
          <strong>{{ name | titleCase }}</strong> ? -->
          {{ $t('validation.remove', { item: name }) }}
        </p></v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn light text @click="dialog = false">{{
          $t('actions.cancel')
        }}</v-btn>
        <v-btn color="primary" text @click="removeValidation">{{
          $t('actions.confirm')
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <!--  -->
    <!-- Validation -->
    <!--  -->
    <v-card v-else>
      <!-- Confirmation -->
      <v-card-title>
        <!-- Company Validation <v-spacer></v-spacer
        ><v-icon left color="success">mdi-account-check</v-icon> -->
      </v-card-title>
      <v-card-text v-if="modal">
        <p class="mt-4">
          <!-- Can you confirm the information for
          <strong>{{ name | titleCase }}</strong> has been verified? -->
          {{ $t('validation.confirm', { item: name }) }}
        </p>
      </v-card-text>
      <v-card-actions v-if="modal"
        ><v-spacer></v-spacer>
        <v-btn light text @click="dialog = false">{{
          $t('actions.cancel')
        }}</v-btn
        ><v-btn color="primary" text @click="modal = false">{{
          $t('actions.confirm')
        }}</v-btn></v-card-actions
      >
      <!-- Validate Date Range -->
      <v-card-text v-if="!modal">
        <p class="mt-4">
          {{ $t('validation.introduceValidationDate') }}
        </p>
        <v-row dense
          ><v-col>
            <base-date-picker
              v-model="validatedFrom"
              label="Valid From"/></v-col
          ><v-col>
            <base-date-picker v-model="validatedTo" label="Valid Until"/></v-col
        ></v-row>
        <v-alert v-if="blockchain" outlined color="warning">{{
          $t('validation.waitingForBlockchain')
        }}</v-alert>
      </v-card-text>
      <v-card-actions v-if="!modal">
        <v-spacer></v-spacer>
        <v-btn light text @click="dialog = false">{{
          $t('actions.cancel')
        }}</v-btn>
        <v-btn
          :loading="blockchain"
          color="primary"
          text
          @click="updateUserGroup"
          >{{ $t('actions.confirm') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    userGroup: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      validatedFrom: null,
      validatedTo: null,
      dialog: false,
      modal: true,
      blockchain: false
    }
  },
  computed: {
    name() {
      return this.userGroup.attributes.name
    },
    isValidated() {
      return !!this.userGroup.attributes.customAttributes.validatedFrom
    }
  },
  created() {
    const today = new Date()
    const currentYear = today.getFullYear()
    this.validatedFrom = new Date().toISOString()
    this.validatedTo = new Date(currentYear, 11, 31).toISOString()
  },
  methods: {
    async updateUserGroup() {
      const blockchainHash = await this.createTransactionHash()
      this.updateUserGroupValues(
        this.validatedFrom,
        this.validatedTo,
        blockchainHash
      )
      this.dialog = false
      await this.$store.dispatch('userGroup/update', this.userGroup)
    },
    async removeValidation() {
      this.updateUserGroupValues('', '', '')
      this.dialog = false
      await this.$store.dispatch('userGroup/update', this.userGroup)
    },

    updateUserGroupValues(dateFrom, dateTo, hash) {
      this.userGroup.attributes.customAttributes.validatedFrom = dateFrom
      this.userGroup.attributes.customAttributes.validatedTo = dateTo
      this.userGroup.attributes.customAttributes.validationHash = hash
    },
    async createTransactionHash() {
      this.blockchain = true
      const response = await this.$store.dispatch(
        'blockchain/create',
        this.userGroup
      )
      await new Promise(r => setTimeout(r, 2000))
      this.blockchain = false
      return response.result
      // return this.createHashString(64)
    }
    // createHashString(length) {
    //   let hash = '#'
    //   const characters =
    //     'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    //   const charactersLength = characters.length
    //   for (var i = 0; i < length; i++) {
    //     hash += characters.charAt(Math.floor(Math.random() * charactersLength))
    //   }
    //   return hash
    // }
  }
}
</script>
