<template>
  <v-data-table
    :headers="headers"
    :items="userGroups"
    :search="search"
    :loading="isLoading"
    sort-by="attributes.name"
  >
    <template v-slot:[`item.attributes.name`]="{ item }">
      <p class="info--text subtitle-2 font-weight-medium mb-1 ">
        {{ item.attributes.name }}
      </p>
    </template>
    <!-- TODO Fix Error when loading data -->
    <template v-slot:[`item.users`]="{ item }">
      <ul
        v-for="(contact, i) in item.attributes.users &&
          item.attributes.users.data"
        :key="i"
        class="pa-0"
      >
        <li v-if="contact.attributes.roles[1] !== 'forest_manager'">
          {{ contact.attributes.info.name }}
        </li>
      </ul>
    </template>

    <template v-slot:[`item.forestManagers`]="{ item }">
      <ul
        v-for="(fm, i) in item.attributes.customAttributes.forestManagers"
        :key="i"
        class="pa-0"
      >
        <li>
          {{ fm.attributes.name }}
        </li>
      </ul>
      <ul class="pa-0 text--secondary">
        <li v-if="!item.attributes.customAttributes.forestManagers.length">
          -
        </li>
      </ul>
    </template>

    <template v-slot:[`item.validated`]="{ item }">
      <validate-user-group :user-group="item" />
    </template>
    <template
      v-slot:[`item.attributes.customAttributes.validatedFrom`]="{ item }"
    >
      <div v-if="item.attributes.customAttributes.validatedFrom">
        {{ item.attributes.customAttributes.validatedFrom | moment('LL') }}
      </div>
    </template>

    <template
      v-slot:[`item.attributes.customAttributes.validatedTo`]="{ item }"
    >
      <div v-if="item.attributes.customAttributes.validatedTo">
        {{ item.attributes.customAttributes.validatedTo | moment('LL') }}
      </div>
    </template>

    <template
      v-slot:[`item.attributes.customAttributes.validationHash`]="{ item }"
    >
      <a
        :href="
          `https://rinkeby.etherscan.io/tx/${item.attributes.customAttributes.validationHash}`
        "
        target="_blank"
      >
        <span
          style="max-width: 100px;"
          class="purple--text text--lighten-1 mb-0 d-inline-block text-truncate"
        >
          {{ item.attributes.customAttributes.validationHash }}
        </span>
      </a>
    </template>

    <template v-slot:[`item.id`]="{ item }">
      <BaseTableActions
        module-name="userGroup"
        :item="item"
        :to-show="{
          name: 'user-group-show',
          params: {
            groupId: item.id,
            category: item.attributes.category
          }
        }"
        :to-edit="{
          name: 'user-group-edit',
          params: {
            groupId: item.id,
            category: item.attributes.category
          }
        }"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config.js'
import ValidateUserGroup from './ValidateUserGroup'
import BaseTableActions from '@/components/buttons/BaseTableActions'
export default {
  components: {
    ValidateUserGroup,
    BaseTableActions
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoading: true,
      headers: this.headersItems()
    }
  },
  computed: {
    ...mapGetters({ userGroups: 'userGroup/userGroups', locale: 'i18n/locale' })
  },
  watch: {
    category: {
      immediate: true,
      async handler(value) {
        this.isLoading = true
        await this.filterUserGroups(value)
        this.headers = this.headersItems()
        this.getHeaders(value)
        this.isLoading = false
      }
    },
    locale() {
      this.headers = this.headersItems()
      this.getHeaders(this.category)
    }
  },
  methods: {
    async filterUserGroups(category) {
      await this.$store.dispatch('userGroup/filter', {
        organizationId: ORGANIZATION_ID(),
        category
      })
    },
    getHeaders(category) {
      if (category === 'customer') this.headers.splice(2, 5)
    },
    headersItems() {
      return [
        {
          text: this.$t('userGroup.companyName'),
          value: 'attributes.name'
        },
        {
          text: this.$tc('user.contact', 2),
          sortable: false,
          value: 'users'
        },
        {
          text: this.$tc('user.forestManager', 2),
          sortable: false,
          value: 'forestManagers'
        },
        {
          text: this.$t('common.validation'),
          sortable: false,
          value: 'validated'
        },
        {
          text: this.$t('common.validFrom'),
          sortable: false,
          value: 'attributes.customAttributes.validatedFrom'
        },
        {
          text: this.$t('common.validUntil'),
          sortable: false,
          value: 'attributes.customAttributes.validatedTo'
        },
        {
          text: this.$t('blockchain.blockchainValidationHash'),
          sortable: false,
          value: 'attributes.customAttributes.validationHash'
        },
        {
          text: '',
          value: 'id',
          sortable: false,
          align: 'end'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.overflow-wrap {
  max-width: 250px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

a {
  text-decoration: none;
}
</style>
