<template>
  <base-index-layout :title="$tc(`user.${category}`, 2)">
    <template v-slot:search>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('common.search')"
        single-line
        outlined
        dense
        hide-details
        class="mr-4"
      ></v-text-field
    ></template>
    <template #new-button>
      <base-new-button class="mx-4" :to="{ path: 'new' }" append />
    </template>
    <user-groups-table :search="search" :category="category"
  /></base-index-layout>
</template>

<script>
import UserGroupsTable from './components/UserGroupsTable'
export default {
  components: {
    UserGroupsTable
  },
  props: {
    category: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      search: ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
